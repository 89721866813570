import React from "react"
import "./index.scss"
import { graphql } from "gatsby"
import { SanityHowWeHelp, NavigationType } from "@utils/globalTypes"
import SEO from "@components/SEO"
import Image from "gatsby-image"
import { Row } from "react-bootstrap"
import { BaseBlockContent } from "@utils/richText"
import BackgroundImage from "gatsby-background-image"
import ScrollUpButton from "@components/ScrollUpButton"
import LateralNavigation from "@components/LateralNavigation"
import useResizeWidth from "@hooks/useResizeWidth"
import HeroTextImage from "@components/hero/HeroTextImage"

type Props = {
  data: {
    sanityHowWeHelp: SanityHowWeHelp
    sanityNavigation: NavigationType
  }
  location: Location
}

const HowWeHelpPage = ({
  data: { sanityHowWeHelp, sanityNavigation },
  location,
}: Props) => {
  const { backgroundColor } = sanityHowWeHelp
  const links = sanityNavigation?.content?.find(
    (item) => item?.slug?.current === `/${location.pathname.split("/")[1]}`
  )
  const { width } = useResizeWidth()
  const metadata = {
    location: location.href,
    ...sanityHowWeHelp.seo,
  }
  const {
    firstSection,
    lastSection,
    heroTextImage,
    middleSectionImage,
  } = sanityHowWeHelp

  const renderContent = () => (
    <>
      {heroTextImage?._rawTitle && heroTextImage?.image?.asset && (
        <HeroTextImage sanityHeroTextImage={heroTextImage} navItems={links} />
      )}
      {firstSection?._rawContent &&
        (firstSection?.backgroundImage ? (
          <BackgroundImage
            id="firstSection"
            Tag={`section`}
            fluid={firstSection?.backgroundImage?.asset?.fluid}
            backgroundColor={firstSection?.backgroundColor || `trasparent`}
            alt={firstSection?.backgroundImage?.alt}
            className="hwh-container hwh-left-padding first-section hwh-gatsby-bg content-padding-top"
            style={{
              backgroundPosition: width > 960 ? "center center" : "top center",
              backgroundSize: width > 960 ? "90%" : "contain",
              opacity: 1,
            }}
          >
            {firstSection?._rawContent && (
              <BaseBlockContent
                blocks={firstSection?._rawContent}
                className="hwh-content-container"
              />
            )}
          </BackgroundImage>
        ) : (
          <section
            style={{
              backgroundColor: firstSection?.backgroundColor || "transparent",
            }}
            className="hwh-container hwh-left-padding first-section content-padding-top"
          >
            {firstSection?._rawContent && (
              <BaseBlockContent
                blocks={firstSection?._rawContent}
                className="hwh-content-container"
              />
            )}
          </section>
        ))}
      {middleSectionImage?.asset && (
        <div className="middleSectionImage">
          <Image
            fluid={middleSectionImage?.asset?.fluid}
            alt={middleSectionImage?.alt}
          />
        </div>
      )}
      {lastSection?._rawContent &&
        (lastSection?.backgroundImage ? (
          <BackgroundImage
            id="lastSection-bg"
            Tag="section"
            fluid={lastSection?.backgroundImage?.asset?.fluid}
            backgroundColor={lastSection?.backgroundColor || "transparent"}
            alt={lastSection?.backgroundImage?.alt || "birchbrook"}
            className="hwh-container hwh-left-padding last-section hwh-gatsby-bg content-padding-top"
            style={{
              backgroundPosition: width > 960 ? "center 30%" : "top center",
              backgroundSize: width > 960 ? "90%" : "contain",
              opacity: 1,
            }}
          >
            {lastSection?._rawContent && (
              <BaseBlockContent
                blocks={lastSection._rawContent}
                className="hwh-content-container"
              />
            )}
          </BackgroundImage>
        ) : (
          <section
            style={{
              backgroundColor: lastSection?.backgroundColor || "transparent",
            }}
            className="hwh-container hwh-left-padding last-section"
          >
            {lastSection?._rawContent && (
              <BaseBlockContent
                blocks={lastSection._rawContent}
                className="hwh-content-container"
              />
            )}
          </section>
        ))}
      {width > 640
        ? lastSection?._rawContent && <ScrollUpButton type="dark" />
        : lastSection?._rawContent && (
            <Row
              className="hwh-backTop-container"
              style={{
                backgroundColor:
                  sanityHowWeHelp?.lastSection?.backgroundColor ||
                  `transparent`,
              }}
            >
              <ScrollUpButton
                type="dark"
                style={{
                  position: "relative",
                  opacity: 1,
                  right: "initial",
                  bottom: "initial",
                }}
              />
            </Row>
          )}
    </>
  )

  return (
    <>
      <SEO {...metadata} />
      {sanityHowWeHelp && (
        <div style={{ backgroundColor: backgroundColor || "transparent" }} className="hwh-container parent-padding">
          {width < 960 ? (
            <div className="lateral-mobile-nav">
              <LateralNavigation
                links={links}
                slug={sanityHowWeHelp.slug}
                isSection={false}
              />
            </div>
          ) : (
            <LateralNavigation
              links={links}
              slug={sanityHowWeHelp.slug}
              isSection={false}
            />
          )}
          {sanityHowWeHelp.backgroundImage?.asset ? (
            <BackgroundImage
              id="hwh"
              Tag={`div`}
              fluid={sanityHowWeHelp?.backgroundImage?.asset?.fluid || null}
              backgroundColor={
                sanityHowWeHelp?.backgroundColor || "transparent"
              }
              alt={sanityHowWeHelp?.backgroundImage?.alt || "birchbrook"}
              style={{
                backgroundPosition:
                  width > 960 ? "center center" : "center center",
                backgroundSize: width > 960 ? "85%" : "contain",
              }}
            >
              {renderContent()}
            </BackgroundImage>
          ) : (
            <div style={{ backgroundColor: backgroundColor || "transparent" }}>
              {renderContent()}
            </div>
          )}
        </div>
      )}
    </>
  )
}

export default HowWeHelpPage

export const query = graphql`
  query sanityHowWeHelp($slug: String) {
    sanityHowWeHelp(slug: { current: { eq: $slug } }) {
      seo {
        description
        keywords
        image
        title
        twitterCard
        twitterCreator
        twitterSite
      }
      title
      slug {
        current
      }
      id
      backgroundColor
      backgroundImage {
        alt
        asset {
          fluid(maxWidth: 10000) {
            ...GatsbySanityImageFluid
          }
        }
      }
      heroTextImage {
        sectionName
        _rawTitle(resolveReferences: { maxDepth: 10 })
        image {
          alt
          asset {
            fluid(maxWidth: 10000) {
              ...GatsbySanityImageFluid
            }
          }
        }
        backgroundColor
      }
      firstSection {
        backgroundColor
        _rawContent(resolveReferences: { maxDepth: 10 })
      }
      lastSection {
        backgroundColor
        backgroundImage {
          alt
          asset {
            fluid(maxWidth: 10000) {
              ...GatsbySanityImageFluid
            }
          }
        }
        _rawContent(resolveReferences: { maxDepth: 10 })
      }
      middleSectionImage {
        alt
        asset {
          fluid(maxWidth: 10000) {
            ...GatsbySanityImageFluid
          }
        }
      }
    }
    sanityNavigation {
      content {
        title
        slug {
          current
        }
        childs {
          title
          slug {
            current
          }
        }
      }
    }
  }
`
