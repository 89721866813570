import React from "react"
import "./index.scss"
import { NavigationLink, SanityHeroTextImage } from "@utils/globalTypes"
import { Row, Col } from "react-bootstrap"
import Image from "gatsby-image"
import { BaseBlockContent } from "@utils/richText"
import useResizeWidth from "@hooks/useResizeWidth"
import { Link } from "gatsby"

type Props = {
  sanityHeroTextImage: SanityHeroTextImage
  navItems?: NavigationLink
}

const HeroTextImage = ({ sanityHeroTextImage, navItems }: Props) => {
  const { _rawTitle, image } = sanityHeroTextImage
  const { width } = useResizeWidth()

  const renderContent = () => (
    <>
      {width > 660 ? (
        <>
          {_rawTitle && (
            <div className="hwh-TextContent">
              {sanityHeroTextImage?.sectionName && (
                <h6>{sanityHeroTextImage?.sectionName}</h6>
              )}
              <BaseBlockContent blocks={_rawTitle} />
            </div>
          )}
          {image && (
            <div className="hwh-image">
              <Image fluid={image?.asset?.fluid} alt={image?.alt} />
            </div>
          )}
        </>
      ) : (
        <>
          <Col md={12} sm={12} xs={12} className="hwh-image">
            {sanityHeroTextImage?.sectionName && (
              <h6>{sanityHeroTextImage?.sectionName}</h6>
            )}
            {image && <Image fluid={image?.asset?.fluid} alt={image?.alt} />}
          </Col>
          {_rawTitle && (
            <Col md={12} sm={12} xs={12} className="hwh-TextContent">
              <BaseBlockContent blocks={_rawTitle} />
            </Col>
          )}
        </>
      )}
      {width < 960 && navItems && (
        <div>
          {navItems?.childs.map((item, index) => (
            <Link
              to={`${navItems?.slug?.current}${item?.slug?.current}`}
              key={index}
              className="hwh-nav-item"
            >
              {" "}
              {item?.title}{" "}
            </Link>
          ))}
        </div>
      )}
    </>
  )

  return (
    <section
      style={{
        backgroundColor: sanityHeroTextImage?.backgroundColor || `#090D31`,
      }}
      className="heroTextImage-container"
    >
      <Row className="content-padding-top heroTextImage-sub-container" noGutters>
        {renderContent()}
      </Row>
    </section>
  )
}

export default HeroTextImage
